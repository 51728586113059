:root {
    --color1: #301B60;
    --color2: #5D24E5;
    --white-color: #fff;
    --c3: #F2F0F5;
    --c4: #DFD3FA;
    --c5: #817799;
    --c6: #04B983;
    --c7: #E52452;
    --c8: #FAD3DC;
    --c9: #CEF4E1;
    --c10: #FADCD3;
    --c11: #F05F0E;
    --c12: #ACA4BF;
  
    --c-grey1: #D2CFDA;
    --c-grey2: #CDCDCD;
    --c-grey3: #7e7e7e;
  
    --c-fb: #3b5998;
    --c-tw: #00acee;
    --c-insta: #8a3ab9;
    --c-yt: #ff0000;
    --c-in: #0077b5;
    --c-mess: #006AFF;
    --c-wa: #25D366;
    --c-ok: #38A54A;
    --c-error: #C84F4F;
  }
  
$layout_width_full: 2000px;
$layout_width_max: 1600px;
$layout_width: 980px;

$layout_width_xlm: 900px;
$layout_width_lgm: 570px;
$layout_width_mdm: 400px;
$layout_width_smm: 300px;
$layout_space: 15px;
$layout_space_xl: 20px;
$layout_space_lg: 20px;

//Breakpoints
$screen-min-min: 320px;
$screen-ssm-min: 401px;
$screen-sm-min: 578px;
$screen-md-min: 768px;
$screen-lg-min: 1101px;
$screen-xl-min: 1351px;
$screen-xxl-min: 1601px;
$screen-xxxl-min: 1701px;

//Breakpoints
$screen-min-max: 319px;
$screen-ssm-max: 400px;
$screen-sm-max: 577px;
$screen-md-max: 767px;
$screen-lg-max: 1100px;
$screen-xl-max: 1350px;
$screen-xxl-max: 1600px;
$screen-xxxl-max: 1700px;
$screen-full-max: $layout_width_full;
$screen-logo-max: 350px;

//VARIABLES
$mq_min: 319px;

$font1: 'Poppins', sans-serif;

  
$fontsize: 14px;
$lineheight: 1.42857143;
$fontweight: 400;
$color: var(--color1);
$background: #fff;
$font: $font1;
$color1: var(--color1);
$color2: var(--color2);

$c1: $color1;
$c2: $color2;
$c3: var(--c3);
$c4: var(--c4);
$c5: var(--c5);
$c6: var(--c6);
$c7: var(--c7);
$c8: var(--c8);
$c9: var(--c9);
$c10: var(--c10);
$c11: var(--c11);
$c12: var(--c12);
$white: var(--white-color);

$colors: $c1, $c2, $c3, $c4, $c5, $c6, $c7, $c8, $c9, $c10, $c11, $c12;

$c_grey1: var(--c-grey1);
$c_grey2: var(--c-grey2);
$c_grey3: var(--c-grey3);

$colors_grey: $c_grey1, $c_grey2, $c_grey3;

//SOCIAL
$c_fb: var(--c-fb);
$c_tw: var(--c-tw);
$c_insta: var(--c-insta);

$c_yt: var(--c-yt);
$c_in: var(--c-in);
$c_mess: var(--c-mess);
$c_wa: var(--c-wa);
$c_ok: var(--c-ok);
$c_error: var(--c-error);


$lineheight10: 1.31;
$lineheight12: 14px;
$lineheight14: 1.42857143;
$lineheight15: 1.46688;
$lineheight16: 22px;
$lineheight16_em: 1.375;
$lineheight18: 1.3333333;
$lineheight20: 1.25;

$grid_space: 20px;
$grid_space_small: 10px;
$grid_space_mid: 40px;
$grid_space_mid2: 30px;
$grid_space_big: 60px;

$header_height: 90px;
$header_offset: 90px;
$header_scrolled: 90px;
$header_height_mobile: 62px;
$header_offset_mobile: 62px;

$zindex_header: 300;



