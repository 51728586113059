.box_video{
  $w: 1010px;
  position: relative;
  flex: 1 1 0%;
  width: 100%;
  border: 15px solid transparent;
  max-width: $w;
  margin: 0 auto;

  $percent: strip-unit($w)/100;

  &_inner{
    @include abs-100;    
    overflow: hidden;  
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
  }

  &_content{   
    position: relative;
    max-height: 100%;
    width: 100%;
    aspect-ratio: 16/9;
    overflow: hidden;    
    //-webkit-mask-image: -webkit-radial-gradient(white, black);
    
    @include mq_vertical{
      aspect-ratio: 1/1;
    }    
    
    > svg {
      width: 100%;
      height: 100%;
      position: absolute;
      border-radius: 30px;
    }
  }  

  &.vertical &_content{
    aspect-ratio: 1/1;
  }

  &_holder{
    @include abs-100;
    background-color: #000;
  }

  &_cover{   
    @include abs-100;
    background: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(10px);
    -webkit-mask-repeat: no-repeat; 
    mask-repeat: no-repeat; 
    -webkit-mask-size: 100%;
    mask-size: 100%;
    border-radius: 15px;
    overflow: hidden;
   /* z-index is the key */
    z-index: 1;
  
    @include mq_vertical{
      // -webkit-mask-image: url('../../layout/rect_v.svg');  
    }     
  }

  &.vertical &_cover{
    // -webkit-mask-image: url('../../layout/rect_v.svg');  
  }

  &_frame{
    > svg{
      @include abs-100;
    }
  }
  
  &_stroke{
    fill: none;
    stroke: #fff;
    stroke-width: 4.5;
    transition: stroke .45s;
  }

  &.ok &_stroke{
    stroke: $c6
  }

  &.error &_stroke{
    stroke: $c7
  }

  img {

  }

  img,
  video{
    @include abs-100;
    
    object-fit: cover;
    border-radius: 15px;

    @include mq_vertical{
      width: 200%;
      height: 200%;
      transform: scale(0.5);
      transform-origin: 0 0;
      border-radius: 30px
    }

    &.mirror {
      transform: rotateY(180deg);

      @include mq_vertical{ 
        transform: scale(0.5) rotateY(180deg);
        transform-origin: 33.33333% 0;
      }
    }
  }

  &_text{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    vertical-align: top;
    color: #fff;
    font-size: 20px;
    font-weight: 400;
    font-size: clamp(12px, calc((100vmin - 200px)/100 * 2.8), 20px);
    text-align: center;
    padding:  .75em;
    min-height: .000001vw;

    @include mq_vertical{
      font-size:  clamp(12px, calc((100vmin - 10px)/100 * 2), 20px);
    }

    .icon:first-child{
      margin-right: .7em;

      @include mq_vertical{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
        transform: translateY(-50%);
      }
    }

    .icon:first-child + *{
      @include mq_vertical{
        padding-top: .8em;
      }   
    }
  }  

  &.vertical &_text{
    font-size:  clamp(12px, calc((100vmin - 10px)/100 * 2), 20px);  

    .icon:first-child{
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      margin: 0 auto;
      transform: translateY(-50%); 
    }

    .icon:first-child + *{
      padding-top: .8em;  
    }
  } 

  &_top{
    text-align: center;
    $left: 15/$percent *1%;
    left: $left;
    width: calc(100% - #{$left}*2);   
    z-index: 5;

    @include mq_vertical{
      top: auto;
      bottom:  calc(50% + var(--h-m-per)/2 + 2%);
      transform: none;
    }
  }

  &.vertical &_top{    
    top: auto;
    bottom:  calc(50% + var(--h-m-per)/2 + 2%);
    transform: none;    
  }

  &_bottom{
    $left: 15/$percent *1%;
    left: $left;
    width: calc(100% - #{$left}*2);   
    z-index: 5;

    @include mq_vertical{
      bottom: $left + 1%;
      transform: none;
    }    
  }
  
  &_camera{
    @include button-reset;
  } 

  &_camera,
  &_button{
    position: absolute;
    right: 15/$percent * 1%;
    top: 15/$percent * 1%;
    overflow: hidden;
    color: #Fff;
    font-weight: 500;
    font-size: clamp(10px, 2vmin, 12px);  
    padding: .8em;
    display: flex;
    align-items: center;
    z-index: 5;

    @include mq_vertical{
      right: 20/$percent * 1%;
      top: 20/$percent * 1%;
    }

    input{
      position: absolute;
      right: 0;
      top: 0;
      font-size: 1000px;
      opacity: 0;
      cursor: pointer;
    }

    .sico{
      position: relative;
      top: -.1em;
      width: 1.66em;
      height: 1.66em;    
    }

    > span{
      display: block;
      flex: 1 1 0%;
      padding-left: .5em;
    }
  }

  &_drop{
    position: absolute;
    right: 15/$percent * 1%;
    top: 15/$percent * 1%;

    @include mq_vertical{
      right: 20/$percent * 1%;
      top: 20/$percent * 1%;
    }
  }

  &_drop &_camera{
    top: auto;
    right: auto;
    position: relative;
  }

  &_dropdown{
    position: absolute;
    top: 100%;
    right: 0;
    background-color: #fff;
    border-radius: 8px;
    min-width: 100%;
    z-index: 10;
    overflow: hidden;
    display: none;

    div {
      display: block;
      padding: 10px 15px;
      font-size: 12px;
      background-color: #fff;

      &:hover,
      &.active{
        background-color: #e9e9e9;
      }
    }
  }

  button:focus ~ &_dropdown,
  button.active ~ &_dropdown{
    display: block;
  }

  &_semi{
    background: rgba(0, 0, 0, 0.26);
    border-radius: 1em;
    backdrop-filter: blur(10px);

    &.error {
      margin: 10px 0;
      width: 100%;
      justify-content: flex-start;
      background: rgba(0,0,0, 0.1);
      font-size: 16px;
      color: $c1;

      &:first-child {
        margin-top: 20px;
      }

      .icon {
        width: 1.2em;
        height: 1.2em;
        flex: 0 0 1.2em;
        padding: 0.2em;
      }
    }
  }

  &_image{
    @include abs-100;
    object-fit: cover;
    border-radius: 30px;
  }

  &.nocamera &_cover,
  &.nocamera &_top,
  &.nocamera &_frame{
    display: none;
  }

}

.box_result{
  
  @include md{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  &_left{
    width: 36%;
    background-color: #fff;
    padding: 35px;
    border-radius: 32px;

    @include mdm{
      width: auto;
    }

    img{
      display: block;
      max-width: 100%;
      border-radius: 12px;
    }

    img + img,
    h2 + img{
      margin-top: 15px;
    }

    > div + div{
      margin-top: 25px;
    }
  }

  &_right{
    width: 57%;

    @include mdm{
      width: auto;
      margin-top: 30px;
    }
  }  

}

.box_steps{
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 25px; 

  @include lgm{
    margin: 0 auto;
    max-width: 600px;
    padding: 15px;
  }

  &_item{
    display: flex;
    align-items: center;
    padding: 8px 20px 8px 8px;
    border-radius: 20px;
    margin: 0 10px;

    &.active{
      background-color: $c4;

      @include lgm{
       // width: 100%;
      }
    }

    &:not(.active){
      @include lgm{
        display: none;
      }
    }
  }

  &_num{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border-radius: 16px;
    color: $color2; 
    font-size: 24px;
    line-height: 1;
    font-weight: 500;
    width: 50px;
    height: 50px;

    @include lgm{
      font-size: 20px;  
    }

    span{
      font-size: 12px;
      @include lg{
        display: none;
      }
    }
  }

  &_text{
    padding-left: 13px;
    font-size: 16px;
    line-height: (18/16);
    flex: 1 1 0%;
    color: $c5;
    text-align: left;
    max-width: 200px;

    br{
      @include lgm{
        display: none; 
      }
    }
  }

  &_item.active &_text{
    color: $color2;
  }
}

.box_buttons{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  max-width: 1010px;
  padding: 20px 15px;

  &_left{
  //  flex: 1 1 0%
  }

  &_right{
    //flex: 1 1 0%;
    text-align: right;
  }
}

.box_msg{
  position: absolute;
  max-width: 50%;
  text-align: center;
  left: 0; 
  right: 0;
  top: 0;
  margin: 0 auto;

  @include mq_vertical{
    position: relative;
    top: auto;
    padding-bottom: 15px;
    max-width: 100%;
  }

  &_cont{
    position: relative;
    margin: 0 auto;
    max-width: 980px;
    padding: 15px 15px 0 15px;
    width: 100%;

    @include mq_vertical{     
      padding: 0 15px 0 15px;
    }
  }

  &_text{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    vertical-align: top;
    font-size: 18px;
    font-weight: 400;
    font-size: clamp(12px, calc((100vmin - 200px)/100 * 2.8), 18px);
    text-align: center;
    min-height: .000001vw;
    padding-top: 40px;

    @include lgm{
      //font-size: clamp(12px, 2.5vmin, 18px);   
    }

    @include mq_vertical{
      font-size: clamp(12px, calc((100vmin - 10px)/100 * 2), 18px);
      padding: 5px 0 0 0;
    }
  }

  .icon{
    position: absolute;
    display: block;
    left: 0; 
    right: 0;
    top: 15px;
    margin: 0 auto;
    transform: translateY(-50%);

    @include mq_vertical{
      position: relative;
      top: auto;
      transform: none;
    }
  
  }
}

.box_process{

  @include md{
    display: flex;
  }  

  &::before,
  &::after{
    @include md{
      content: '';
      display: block;
      height: 2px;
      flex: 0 0 20px;
      align-self: center;
      background-color: $c_grey1;
    }
  }

  .item_process{
    flex: 1 1 0%;

    &:first-child{
      order: -1;
    }

    &:last-child{
      order: 1;
    }     
  }

  .item_process + .item_process{
    @include mdm{
      margin-top: 10px;
    }
  }
}

.page_content{
  font-size: 18px;
  line-height: (24/18);

  @include lgm{
    font-size: 16px;
  }

  p{
    margin: 1em 0;
  }
}

.reset_margins{
  > *:first-child{
    margin-top: 0;
  }

  > *:last-child{
    margin-bottom: 0;
  }
}

.upload-box {
  margin-top: 15px;
  border-color: $c12;

  .inner {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-direction: column;
    padding: 10px;

    h5 {
      margin: 0;
      margin-top: 10px;
      font-size: 15px;
      font-weight: normal;
    }

    .photo-remove {
      position: absolute;
      top: 10px;
      right: 10px;
      border: none;
      padding: 0;
      background: transparent;

      svg {
        stroke: $c3;
      }
    }
  }
}

.title-select {
  max-width: 700px;
  margin: 0 auto;
}

.languages {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}