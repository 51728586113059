#root {
  background-color: $white;
}

//TITLEs
.title_big{
  @include title_big
}

.title_h1{
  @include title_h1
}

.title_h2{
  @include title_h2
}

.upload-box{
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  padding: 0;
  border: 2px solid rgba($c12,1);
  cursor: pointer;

  display: flex;

  &.photo::before{
    @include pseudo-pad-percent(16,9);
  }

  &.face::before{
    @include pseudo-pad-percent(1,1);
  }

  &-inner{
    margin: auto;
    text-align: center;
    pointer-events: none;
  }

  &.dz-started &-inner{
    display: none;
  }

  input{
    position: absolute;
    right: 0;
    top: 0;
    font-size: 1000px;
    opacity: 0;
    cursor: pointer;
    pointer-events: none;
  }

  &-title{
    margin: 5px  0 0 0;
    font-size: 14px;
    font-weight: 500;
  }

  .dz-message{
    display: none
  }

  .dz-preview{
    @include abs-100;
    margin: 0;

    .dz-image{
      @include abs-100;
      border-radius: 0;
      
      img{
        @include abs-100;
        object-fit: cover
      }
    }

    .dz-error-message{
      position: absolute;
      width: 100%;
      bottom: 0;
      left: 0;
      right: auto;
      top: auto;

      &::after{
        display: none;
      }
    }    
  }

  .dz-remove{
    position: absolute;
    width: 40px;
    height: 40px;
    right: 5px;
    top: 5px;
    color: #fff;
    border-radius: 8px;
    background-color: $c7;
    padding: 10px;
    z-index: 22;
  }
    
}

.btn_basic{
  @include button-reset;
  position: relative;
  display: inline-flex;  
  align-items: center;
  padding: 8px;
  color: #fff;
  background-color: $color2;
  font-size: 16px;
  line-height: $lineheight16_em;
  border-radius: 32px;
  transition: all .25s;

  &:hover{
    background-color: transparent;
    color: $color2;
  }

  &.bordered {
    border: 2px solid $color2;
  }

  @include lgm{
    font-size: 12px;
    line-height: $lineheight12;
  }

  .text{
    display: block;
    padding: 13px 0;

    @include lgm{
      padding: 10px 0;
    }

    &:first-child:last-child{
      padding-left:20px;
      padding-right: 20px;   
      text-align: center;

      @include lgm{
        padding-left:16px;
        padding-right: 16px;   
      }
    }    
  }

  .arrow ~ .text:last-child{
    padding-left:10px;
    padding-right: 12px;   

    @include lgm{
      
    }
  }

  &.more{
    overflow: hidden;
    transform: translateZ(0);
    color: $color;
    background-color: #fff;
    box-shadow: inset 0 0 0 2px $c_grey1;    

    @include lgm{
      padding: 7px 8px;
    }

    &::before{
      content: '';
      @include abs-100;
      background-color: $c4;         
      z-index: -1;
      clip-path: circle(calc(0.0% + 5px) at calc(0% + 33px) calc(0% + 33px));
      transition: all .7s;

      @include lgm{
        clip-path: circle(calc(0.0% + 5px) at calc(0% + 25px) calc(0% + 24px));  
      }
    }

    &:hover{
      background-color: #fff;  
      &::before{
        clip-path: circle(100% at 50% 50%);  
      }
    }
  }

  .arrow{
    display: block;
    color: $color2;
    border-radius: 50%;
    padding: 13px;
    width: 46px;
    height: 46px;
    flex: 0 0 46px;
    background-color: $c4;
    margin: 1px;

    @include lgm{
      width: 34px;
      height: 34px;
      flex: 0 0 34px;
      padding: 9px;
    }
  }

  &[disabled]{
    opacity: .5;
    color: #fff;
    pointer-events: none;
  }
}

//INPUT TEXT
@mixin input_default_transform($input, $elm, $trans, $color){
  @at-root #{$input}:focus ~ #{$elm}{   
    transform: $trans;
    opacity: 1;
    color: $color;
  }

  @at-root #{$input}:not(:placeholder-shown) ~ #{$elm}{   
    transform: $trans;
    opacity: 1;
    color: $color;
  }

  @at-root #{$input}:focus:-ms-input-placeholder ~ #{$elm}{   
    transform: $trans;  
    opacity: 1;
    color: $color;
  }

  @at-root #{$input}:not(:-ms-input-placeholder) ~ #{$elm}{   
    transform: $trans;
    opacity: 1;
    color: $color;
  }  
}

//INPUT TEXT
.input_text{
  $elm: &;
  $trans_placeholder: translate(0,-4px) scale(.7142);    

  $pad: 20px 44px 8px 10px;

  @include input-reset;
  padding: $pad;
  font-weight: 400;
  font-size: 14px;
  line-height: $lineheight14;
  color: $color;
  resize: vertical;
  text-align: left;
  border-radius: 8px;
  border: 2px solid $c12;
  transition: border .25s;

  &:disabled {
    cursor: not-allowed;
    opacity: .5;
  }

  &:focus{
    border-color: $color2;
  }

  @include placeholder(#fff, 0);

  @at-root textarea#{&}{
    min-height: 60px;
  }

  &_cont{
    position: relative;
    text-align: left;
  }

  &_placeholder{
    position: absolute;
    font-size: 14px;
    line-height: $lineheight14;
    font-weight: 400;
    padding: 16px 0 16px 0;
    pointer-events: none;
    transform-origin: 0% 0%; 
    top: 0;
    left: 12px;
    width: calc(100% - 44px - 10px);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    transform-origin: 0 0;
    transition: transform .25s, opacity .25s;    
    
    small {
      opacity: 0;
    }
  }

  @include input_default_transform(&, #{& + '_placeholder'}, $trans_placeholder, $color2);

  &.invalid{   
    border-color: $c_error;
  }
}

//CHECKBOX
.input_checkbox{
  display: inline-block;
  vertical-align: top;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance:none;
  border-radius: 0;
  width: 24px;
  height: 24px;
  flex: 0 0 24px;
  color: #fff;
  background-color: rgba(#fff, 0);
  border-radius: 8px;
  border: 2px solid $c12;
  accent-color: rgba(#fff, 0);
  cursor: pointer;
  outline: none;
  transition: border .2s;

  &:hover:not(:checked){
    box-shadow: 0 0 0 20px rgba($c12,.2);
    clip-path: circle(100% at 50% 50%);
    outline: none;
  }

  &:checked{
    border: 2px solid transparent;
    outline: none;
    //background-color: $color2;
  }

  &::after{
    content: '';
    position: absolute;   
    width: 24px;
    height: 24px;
    flex: 0 0 24px;
    background: $color2 url(../../layout/check.svg) center center no-repeat;
    margin: -2px 0 0 -2px;
    border-radius: 8px;
    opacity: 0;
    transition: opacity .2s;
  }

  &:checked::after{
    opacity: 1;
  }

  &::before{
    content: '';
    position: absolute;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin: -2px 0 0 -2px;
    background-color: $color2;
    opacity: 0;
  }
  
  &:checked::before{
    opacity: 1;
    animation: check-pulse .4s cubic-bezier(0.455, 0.03, 0.515, 0.955) forwards; 
  }

  &_cont{
    display: flex;
    align-items: flex-start;
  }

  &_text{
    flex: 1 1 0%;
    padding: .1em 0;
    padding-left: .8em;
    font-size: 14px;
    align-self: center;
    line-height: (18/14);
    user-select: none;
  }
}

@keyframes check-pulse {
  0% {
    transform: scale(1); 
  } 100% {
    transform: scale(3);
    opacity: 0; 
  }
}

.icon{
  display: inline-block;
  vertical-align: middle;
  border-radius: .6em;
  flex: 0 0 2em;
  width: 2em;
  height: 2em;
  background-color: $c6;
  font-size: clamp(12px, calc((100vmin - 200px)/100 * 2.8), 20px);
  min-height: .000001vw;
  color: #fff; 
  padding: .5em;

  &.type2{
    background-color: $c11;
  }
  
  &.type3{
    background-color: $c7;
  }
  
  &.type4{
    background-color: $c_error;
  }

  @include mq_vertical{
    font-size: clamp(12px, calc((100vmin - 10px)/100 * 2), 20px);
  }
}

.icon2{
  display: inline-block;
  vertical-align: middle;
  border-radius: 1em;
  flex: 0 0 3.25em;
  width: 3.25em;
  height: 3.25em;
  background-color: $c4;
  font-size: clamp(16px, calc((100vmin - 200px)/100 * 2.8), 20px);
  min-height: .000001vw;
  color: $color2; 
  padding: .8em;

  &.mid{
    flex: 0 0 2.5em;
    width: 2.5em;
    height: 2.5em;
    padding: .45em;
  }

  &.type2{
    background-color: $c8;
    color: $c7;
  }

  &.type3{
    background-color: $c9;
    color: $c6;
  }

  &.type4{
    background-color: $c10;
    color: $c11;
  }

  @include mq_vertical{
    font-size: clamp(16px, calc((100vmin - 10px)/100 * 2), 20px);
  }
}

.loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $c3;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    max-width: 90px;
  }
}

#floating-notifications {
  position: fixed; 
  top: 0;
  right: 0;
  width: max(200px, 20%);
  padding: 10px;
  z-index: 200;
  align-items: center;

  .floating-notification {
      padding: 15px;
      border-radius: 10px;
      background: $background;
      font-size: 14px;
      color: var(--secondary);
      margin: 0 auto 10px;
      box-shadow: rgba(0, 0, 0, 0.2) 0 0 10px 0px;
      position: relative;
      cursor: pointer;
      width: auto;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 15px;

      .icon {
          width: 1.2em;
          height: 1.2em;
          flex: 0 0 1.2em;
          padding: 0.25em;
      }

      &:last-child {
          margin-bottom: 0;
      }

      &.-error {
          color: $c_error;

          .icon {
              stroke: $c_error;
          }
      }
      &.-warning {
          color: $c11;

          .icon {
              stroke: $c11;
          }
      }
      &.-success {
          color: $c_ok;

          .icon {
              stroke: $c_ok;
          }
      }
  }
}

.box_video {
  .box_video_content.box_error_verification {
    .image {
      width: 200px;
      height: auto;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: .5;

      path {
        fill: $color2;
      }
    }
  }
}

.box_process_type {
  .item_process {
    display: flex;
    flex-direction: column;
    gap: 20px;
  } 

  .item_process_title {
    text-align: center;
  }

  .icon2 {
    width: 4em;
    height: 4em;
    flex: 0 0 4em;
  }

  .canvas {
    width: 15em;
    height: 15em;
    flex: 0 0 15em;
    background: var(--c3);

    canvas {
      width: 100%;
      height: 100%;
    }
  }
}

.wrapper-history {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;

  @media screen and (min-width: 768px) {
    flex-direction: row;
    align-items: flex-start;
  }

  .history-table-wrapper {
    overflow-y: auto;
    max-height: 15em;

    @media screen and (min-width: 768px) {
      height: 15em;
    }
  }

  .history {
    display: table;

    .history_item {
      display: table-row;
      
      >* {
        display: table-cell;
      }

      .history_time {
        padding-right: 10px;
        font-weight: bold;
        color: $c1;
      }

      .history_message {
        width: 320px;
      }

      &:not(:last-child) {
        >* {
          border-bottom: 1px solid $c4;
        }
      }
    }
  }
}