@font-face {
  font-family: 'Poppins';
  src: url('../../fonts/700.woff2') format('woff2'),
      url('../../fonts/700.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('../../fonts/400.woff2') format('woff2'),
      url('../../fonts/400.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('../../fonts/500.woff2') format('woff2'),
      url('../../fonts/500.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
