.item_type{
  display: block;
  border: 2px solid $c_grey1;
  border-radius: 32px;
  background-color: #fff;
  padding: 25px;
  transition: box-shadow .3s;
  cursor: pointer;

  &:hover{
    box-shadow: 0 5px 10px rgba(0,0,0,.2);
  }

  &.disabled {
    cursor: not-allowed;
    opacity: .4;
    pointer-events: none;
  }
}

.item_process{
  position: relative;
  display: flex;
  align-items: center;
  border: 2px solid $c_grey1;
  border-radius: 32px;
  background-color: #fff;
  padding: 25px;
  transition: box-shadow .3s;

  &_title{
    font-size: 16px;
    line-height: (18/16);
    padding-left: 13px;
    flex: 1 1 0%;
  }

  &_num{
    position: absolute;
    top: -10px;
    background: $c6;
    box-shadow: 0px 2px 12px rgba(129, 119, 153, 0.26);
    border-radius: 10px;
    color: #fff;
    font-weight: 500;
    text-align: center;
    width: 28px;
    height: 28px;
    line-height: $lineheight14;
    font-size: 14px;
    padding: 4px 0;
  }
}