@mixin button-reset {
  display: inline-block;
  vertical-align: top;
  border: 0;
  background: none;
  padding: 0;
  -webkit-tap-highlight-color: transparent;
}

@mixin input-reset {
  display: block;
  width: 100%;
  border: 0;
  outline: none;
  background: none;
}

@mixin list-reset {
  margin: 0;
  padding: 0;
  list-style: none;  
}

@mixin title-reset{
  display: block;
  margin-top: 0;
  margin-bottom: 0;
}

@mixin placeholder($color, $opacity, $class: ''){  
  &#{$class}::-webkit-input-placeholder{color: rgba($color, $opacity); opacity: 1;}
  &#{$class}::-moz-placeholder{color: rgba($color, $opacity); opacity: 1;}
  &#{$class}:-ms-input-placeholder{color: rgba($color, $opacity); opacity: 1;}
  &#{$class}:-moz-placeholder{color: rgba($color, $opacity); opacity: 1;}
}

@mixin bg-cover{
  background:{
    position: center;
    repeat: no-repeat;
    size: cover;
  }
}

@mixin abs-100(){
  position: absolute;
  left: 0;
  top: 0;  
  width: 100%;
  height: 100%;
}

@mixin abs-100neg(){
  $var: -2px;
  position: absolute;
  left: $var;
  right: $var;
  bottom: $var;
  top: $var;
}

@mixin img-cover(){
  @include  abs-100();
  object-fit: cover;
}

@mixin input-hide(){
  position: absolute;
  left: 0;
  top: 0;  
  width: 1px;
  height: 1px;
  border: 0;
  outline: none;
  opacity: 0;
}

@mixin img-abs-hidden($opacity:1){
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  opacity: $opacity;
}

@mixin pseudo-pad($pad){
  content: '';
  display: block;
  padding-bottom: $pad;
}

@mixin pseudo-pad-percent($w, $h){
  @include pseudo-pad( calc( (#{$h}/#{$w}) * 100%) );  
}

@mixin aspect-percent($w, $h){
  width: calc((#{$w}/(#{$game_width}/100))*1%);
  &:before{
    @include pseudo-pad( calc( (#{$h}/#{$w}) * 100%) );  
  }
}

//MIXIN
@mixin createclass($a, $val, $prop, $unit: '', $prefix: '', $suffix: '') {    
  @each $i in $a {
    $v: str-replace($i, '.');
    $v: str-replace($v, ',');
    @at-root #{'.' + $val + $prefix + $i + $suffix} {
      #{$prop}: #{$i + $unit} 
    }
  } 
}  

@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }

  @return $number;
}

@function hex-to-rgb($hex) {
  @return red($hex), green($hex), blue($hex);
}

