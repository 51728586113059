.footer{  
  padding: 20px 0;

  @include lgm{
    padding: 10px 20px;
  }

  &_logo{
    display: block;
    margin-left: auto;
    width: 130px;

    @include lgm{
      width: 100px;
    }

    img{
      display: block;
      width: 100%;
      height: auto;
    }
  }
}