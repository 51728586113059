.main{
  display: flex;
  position: relative;
  flex-direction: column;
  border-radius: 32px;
  background-color: $c3;
    
  @include lgm{      
    border-radius: 16px; 
  }

  &_top{
    border-bottom: 1px solid rgba(129, 119, 153, 0.21);
  }

  &_bottom{
    border-top: 1px solid rgba(129, 119, 153, 0.21);
  }
}