.header{
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include lgm{
    padding: 15px 20px;
  }

  a{
    display: block;
    width: 190px;
    height: 40px;
    color: $color2;
    
    @include lgm{
      width: 170px;
    }

    img{
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: left center;
    }
  }

  .languages {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    li {
      margin-left: 8px;
      padding: 2px 5px;
      border-radius: 5px;
      cursor: pointer;
      transition: all .5s ease;

      &:hover {
        background: $c5;
        color: $background;
      }

      &.active { 
        background: $c2;
        color: $background;
      }
    }
  }
}